import React from 'react';
import { AppInputTextFormik } from 'primitives/input/appInputText';
import { FormikProvider } from 'formik';
import { resetPassword } from 'appAPI';
import { ShText } from '@shoootin/translations';
import { getFrontMessage } from 'appMessageDefinitions';
import { useAppFormik } from '../../../primitives/input/formikUtils';
import { AppButton } from '../../../primitives/appButton';
import { FormError } from '../../../primitives/input/formError';

const RecoverForm = () => {
  const { form, isSubmitSuccess, globalError } = useAppFormik({
    initialValues: { email: '' },
    submitAppForm: (values) => resetPassword(values.email),
  });
  const content = isSubmitSuccess ? (
    <div className="field-row space">
      <ShText
        message={getFrontMessage('common_actions_forgottenPasswordLinkSent')}
      />
    </div>
  ) : (
    <>
      `{globalError && <FormError error={globalError} />}
      <div className="field-row">
        <span>
          <AppInputTextFormik
            form={form}
            name="email"
            placeholder="Email"
            modifier="large"
          />
        </span>
      </div>
      <div className="field-row space">
        <AppButton modifier="large" onClick={() => form.submitForm()}>
          <ShText message={getFrontMessage('common_actions_submit')} />
        </AppButton>
      </div>
    </>
  );

  return (
    <FormikProvider value={form}>
      <div className="form-section half">{content}</div>
    </FormikProvider>
  );
};

export default RecoverForm;
