import React from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H2 } from 'primitives/appTitle';
import AppSection from 'primitives/appSection';
import PatternSvg from 'components/svg/patternSvg';
import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import './frontLogin.less';
import ChevronPrevSvg from 'components/svg/picto/chevronPrevSvg';
import RecoverForm from './components/recoverForm';
import { defineMessages } from 'react-intl';
import { ShText } from '@shoootin/translations';
import { getFrontMessage } from 'appMessageDefinitions';

const prefix = 'front_lostPassword';

const messages = defineMessages({
  pageTitle: {
    id: `${prefix}_pageTitle`,
    defaultMessage: 'Lost password',
  },
  pageSubtitle: {
    id: `${prefix}_pageSubtitle`,
    defaultMessage: 'Enter your email address to recover your password.',
  },
});

const RecoverPage = () => (
  <MainLayout
    className="page-login light"
    showContact={false}
    pageName="recoverPassword"
  >
    <AppSection
      header={
        <div>
          <H2>
            <ShText message={messages.pageTitle} tagName="span" />
            <PatternSvg />
          </H2>
          <ShText message={messages.pageSubtitle} tagName="p" />
        </div>
      }
      className="login"
    >
      <RecoverForm />
      <AppInternalLink className="login-link" to={AppInternalPaths.login}>
        <ChevronPrevSvg />{' '}
        <ShText message={getFrontMessage('common_actions_cancel')} />
      </AppInternalLink>
    </AppSection>
  </MainLayout>
);

export default RecoverPage;
